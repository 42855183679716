.wrapper {
    width: 100%;
    padding-bottom: 7px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.3vh;
}

.header > div {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.header > div.left {
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
}

.header > div.right {
    align-items: flex-end;
    justify-content: space-between;
    flex: 1;
}

.header > div.center {
    align-items: center;
    justify-content: space-between;
}

.header > div.right .name {
    text-align: end;
}

.header .name {
    user-select: none;
    text-transform: uppercase;
    font-size: calc((1.2vh + 1.1vw) / 2);
    letter-spacing: 0.04em;
    line-height: 160%;
    font-weight: 200;
}

.header .value {
    user-select: none;
    font-family: 'Charis';
    font-style: normal;
    font-weight: 700;
    font-size: calc((2.35vh + 2.3vw) / 2);
    line-height: 130%;
}

.header .value.pause {
    cursor: pointer;
}

.startCounterFlex {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startCounterWrapper {
    display: flex;
    border: 1px solid #cdb584;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    position: relative;
}

.number {
    transition: all 0.3s;
}

.number.main {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    color: #cdb584;
    font-weight: 200;
    font-size: 30px;
    line-height: 130%;
}

.number.right {
    position: absolute;
    color: rgba(205, 181, 132, 0.3);
    font-size: 20px;
    top: 50%;

    transform: translate(0, -50%);
    right: -75px;
}

.number.left {
    position: absolute;
    color: rgba(205, 181, 132, 0.3);
    font-size: 20px;
    top: 50%;

    transform: translate(0, -50%);
    left: -75px;
}

.number.tooLeft {
    position: absolute;
    color: transparent;
    font-size: 20px;
    top: 50%;

    transform: translate(0, -50%);
    left: -100px;
}

.number.tooRight {
    position: absolute;
    color: transparent;
    font-size: 20px;
    top: 50%;

    transform: translate(0, -50%);
    right: -100px;
}

.questionWrapper {
    position: relative;
    margin-top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.questionImg {
    width: 100%;
    max-height: 500px;
    height: 45vh;
    object-fit: cover;
}

.variants {
    margin-top: 3.1vh;
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.variantButton {
    flex: 1;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    background: none;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 25px;
    padding: 14px 15px 14px 20px;
    height: 47px;
    font-weight: 500;
    font-size: calc((1.45vh + 1.4vw) / 2);
    line-height: 120%;
}

.variantButtonDisabled {
    cursor: default;
    pointer-events: none;
}

.buttonExit {
    border: none;
}

.resultWrapper {
    position: relative;
    text-align: center;
    margin: auto 0;
}

.resultTitle {
    font-weight: 200;
    font-size: calc((2.2vh + 1.7vw) / 2);
    line-height: 110%;
    color: #cdb584;
    margin-top: 0;
    margin-bottom: 2.3vh;
    text-transform: uppercase;
}

.resultText {
    font-weight: 200;
    font-size: calc((1.2vh + 1.2vw) / 2);
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 0;
}

.timerWrapper {
    border: 0.645px solid #cdb584;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
}

.replayButtons {
    margin-top: 3.9vh;
    gap: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mtsImg {
    max-width: 550px;
    width: 100%;
    margin-top: 0.35vh;
    margin-bottom: 1.3vh;
}

@media (max-width: 768px) {
    .header .name {
        font-size: calc((1.55vh + 1.55vw) / 2);
    }

    .header .value {
        font-size: calc((2.9vh + 2.75vw) / 2);
    }

    .variantButton {
        font-size: calc((2vh + 2vw) / 2);
    }

    .resultTitle {
        font-size: calc((2.9vh + 2.6vw) / 2);
    }

    .resultText {
        font-size: calc((2.1vh + 2.1vw) / 2);
    }
}

@media (max-width: 576px) {
    .number.right {
        right: -43px;
    }

    .number.left {
        left: -43px;
    }

    .number.tooLeft {
        left: -60px;
    }

    .number.tooRight {
        right: -60px;
    }
}
