.largeTitle {
    font-weight: 200;
    font-size: calc((2.2vh + 1.7vw) / 2);
    line-height: 110%;

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #cdb584;

    margin-bottom: 2.5vh;
    margin-top: 0;
}

.mediumTitle {
    margin-top: 0;
    margin-bottom: 1.85vh;
    font-family: 'Charis';
    font-weight: 400;
    font-size: calc((2vh + 1.65vw) / 2);
    line-height: 130%;
    text-align: center;
}

.text {
    font-style: normal;
    font-weight: 300;
    font-size: calc((1.2vh + 1.1vw) / 2);
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 3.2vh;

    text-align: center;
}

.mtsText {
    margin-bottom: 0.6vh;
}

.chooseTextUp {
    margin-bottom: 3vh;
}

.chooseTextDown {
    margin-top: 2.2vh;
    margin-bottom: 0;
}

.chooseWrapper {
    margin-bottom: 3.4vh !important;
}

.timerWrapper {
    border: 1px solid #cdb584;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 2.1vh;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timerWrapper div,
.timerWrapper svg {
    width: 34px !important;
    height: 34px !important;
}

.ruleCityImg {
    max-height: 22vh;
    max-width: 100%;
    margin-bottom: 2.1vh;
}

.min3Wrapper {
    text-align: center;
    width: 100%;
    flex: 1;
}

.chooseCityImgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1;
}

.variantRule {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    width: 140px;
}

.variantRule.right {
    align-items: flex-start;
}

.variantRule > div {
    padding: 9px;
    background-color: #fff;
    color: #000000;
    border-radius: 50%;
    text-align: end;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
}

.variantRule span {
    text-align: end;
    font-size: 12px;
}

.chooseCityImgWrapper .variant > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
}

.chooseCityImg {
    max-width: 100%;
    max-height: 22vh;
}

.mtsImg {
    max-width: 550px;
    width: 100%;
}

.ruleVariantsMobile {
    display: none;
}

@media (max-width: 768px) {
    .text {
        font-size: calc((2.1vh + 2.1vw) / 2);
    }

    .largeTitle {
        font-size: calc((2.9vh + 2.6vw) / 2);
    }

    .mediumTitle {
        font-size: calc((2.45vh + 2.45vw) / 2);
    }
}

/* @media (max-width: 992px) {
    .largeTitle {
        font-size: 40px;
    }

    .mediumTitle {
        font-size: 28px;
    }

    .text {
        font-size: 18px;
    }
}

@media (max-height: 600px) {
    .largeTitle {
        font-size: 35px;
    }

    .mediumTitle {
        font-size: 24px;
    }

    .text {
        font-size: 15px;
    }
}

@media (max-width: 576px) {
    .ruleVariantsMobile {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 15px;
    }

    .variantRule {
        flex-direction: row;
        align-items: center !important;
        flex: 1;
        width: unset;
    }

    .variantRule:not(.right) {
        justify-content: flex-end;
    }

    .chooseCityImgWrapper {
        margin-left: 0;
        margin-right: 0;
    }

    .chooseCityImgWrapper .variantRule {
        display: none;
    }

    .ruleVariantsMobile > .variant {
        display: flex;
    }

    .largeTitle {
        font-size: 32px;
    }

    .mediumTitle {
        font-size: 20px;
    }

    .text {
        font-size: 14px;
    }
} */
