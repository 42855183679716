@font-face {
    font-family: 'Nunito';
    src: url('/public/fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/public/fonts/Nunito-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/public/fonts/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charis';
    src: url('/public/fonts/CharisSIL-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

body {
    height: 100vh;
    overflow: hidden;
}

#game {
    padding-top: 110px;
    width: 100%;
    height: 100vh;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-family: Nunito;
    box-sizing: border-box;
}

#start-popup {
    padding-top: 105px;
    width: 100%;
    height: 100vh;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-family: Nunito;
    box-sizing: border-box;
	z-index: -1
}

@media (max-width: 992px) {
    #start-popup {
        padding: 0 20px;
    }

    #game {
        padding: 0 20px;
    }
}
