.wrapper {
    background: #181818;
    color: #ffffff;
    position: relative;
    padding: 45px 40px 40px 40px;
    box-sizing: border-box;
    max-width: 480px;
    width: 100%;
    border: 1px solid transparent;
}

.wrapperRule {
    padding-top: 45px;
    max-height: 620px;
    display: flex;
    flex-direction: column;
}

.wrapperGame {
    padding-top: 36px;
}

.wrapper.correct {
    border: 1px solid rgba(0, 255, 71, 0.9);
}

.wrapper.incorrect {
    border: 1px solid rgba(255, 0, 0, 0.9);
}

.closeIcon {
    position: absolute;
    top: -29px;
    right: -33px;
    color: #c7c7c7;
    cursor: pointer;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 45px 30px 40px 30px;
    }

    .closeIcon {
        position: absolute;
        top: -40px;
        right: 0px;
        color: #c7c7c7;
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    .wrapper {
        padding: 40px 30px;
    }
}
