.wrapper {
    background: #181818;
    color: #ffffff;
    border: 1px solid #cdb584;
    position: relative;
    padding: 45px 40px 40px 40px;
    text-align: center;
    max-width: 480px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.moveWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
}

.moveContent {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.moveText {
    user-select: none;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;

    text-align: center;
}

.closeIcon {
    position: absolute;
    top: -29px;
    right: -33px;
    color: #c7c7c7;
    cursor: pointer;
}

.largeTitle {
    font-weight: 200;
    font-size: calc((2.2vh + 1.7vw) / 2);
    line-height: 110%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #cdb584;

    margin-bottom: 2.5vh;
    margin-top: 0;
}

.textBold {
    margin-top: 0;
    margin-bottom: 2.5vh;
    font-family: 'Charis';
    font-weight: 400;
    font-size: calc((2vh + 1.65vw) / 2);
    line-height: 130%;
    text-align: center;
}

.textMobile {
    display: none;
}

.text {
    font-style: normal;
    font-weight: 200;
    font-size: calc((1.2vh + 1.1vw) / 2);
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 3.2vh;

    text-align: center;
}

.textScroll {
    display: block;
    overflow-y: auto;
    max-height: 35vh;
    padding-right: 6px;
}

.textScroll::-webkit-scrollbar {
    width: 4px;
}

.textScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.textScroll::-webkit-scrollbar-thumb {
    background-color: #cdb584;
    outline: none;
    width: 2px;
}

.icons {
    margin-bottom: 3vh;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.iconWrap {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.16496px solid #cdb584;
    border-radius: 50%;
}

.nextBtn {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    background: none;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 25px;
    padding: 14px 15px 14px 20px;
    font-weight: 500;
    font-size: calc((1.45vh + 1.4vw) / 2);
    line-height: 120%;
    width: fit-content;
}

.brMobile {
    display: none;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 45px 30px 40px 30px;
    }

    .brMobile {
        display: inline;
    }

    .brDesk {
        display: none;
    }

    .textDesk {
        display: none;
    }

    .textMobile {
        display: inline;
        font-size: calc((2.1vh + 2.1vw) / 2);
    }

    .largeTitle {
        font-size: calc((2.9vh + 2.6vw) / 2);
    }

    .textBold {
        font-size: calc((2.45vh + 2.45vw) / 2);
    }

    .closeIcon {
        position: absolute;
        top: -40px;
        right: 0px;
        color: #c7c7c7;
        cursor: pointer;
    }

    .nextBtn {
        font-size: calc((2vh + 2vw) / 2);
    }
}

/* @media (max-width: 992px) {
    .wrapper {
        padding: 40px;
    }

    .largeTitle {
        font-size: 40px;
    }

    .text {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .wrapper {
        padding: 40px 30px;
    }

    .largeTitle {
        font-size: 32px;
    }

    .text {
        font-size: 14px;
    }

    .iconWrap {
        width: 70px;
        height: 70px;
    }

    .iconWrap > div {
        transform: scale(0.58);
    }

    .nextBtn {
        font-size: 11px;
        line-height: 120%;
    }
} */
