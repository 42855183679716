.content {
    flex: 1;
}

.stepper {
    min-height: 2px;
    width: 90%;
    background: #30302e;
    position: relative;
    margin: 0 auto;
}

.stepperLight {
    position: absolute;
    content: '';
    width: 25%;
    height: 2px;
    background: #cdb584;
    transition: 0.5s;
}

.buttonsWrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 3.9vh;
}

.buttonsWrapper button {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    background: none;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 25px;
    padding: 14px 15px 14px 20px;
    font-weight: 500;
    font-size: calc((1.45vh + 1.4vw) / 2);
    line-height: 120%;
}

@media (max-width: 768px) {
    .buttonsWrapper button {
        font-size: calc((2vh + 2vw) / 2);
    }
}
